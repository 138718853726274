<template>
  <div>
    <NuxtLayout>
      <section page-error>
        <section hero-centered :style="[sectionStyles]">
          <div class="container">
            <div class="section-row">
              <div class="section-content">
                <h1 class="section-title" v-if="blok?.title && title != ''" v-html="title"></h1>
                <div class="section-description" v-if="blok?.description && description != ''" v-html="description"></div>
                <div v-if="blok?.buttons?.length > 0" class="section-buttons">
                  <StoryblokComponent v-for="blok in blok.buttons" :key="blok._uid" :blok="blok" />
                </div>
              </div>
            </div>
          </div>
          <div class="container" v-if="blok.image_bottom?.filename" :class="{ 'container--fluid': blok.style === 'full-width' }">
            <figure class="section-image" :class="{ 'section-has-mobile': blok.image_bottom_mobile?.filename }">
              <img v-if="blok.image_bottom?.filename" :src="optimizeImage(blok.image_bottom.filename)" :alt="`Hero Image ${blok.image_bottom.alt}`" class="section-image-element" />
              <img v-if="blok.image_bottom_mobile?.filename" :src="optimizeImage(blok.image_bottom_mobile.filename)" :alt="`Hero Image Mobile ${blok.image_bottom_mobile.alt}`" class="section-image-element-mobile" />
            </figure>
          </div>
        </section>
      </section>
    </NuxtLayout>
  </div>
</template>
<style src="~/assets/scss/section/hero-centered.scss" lang="scss"></style>
<script setup>
const props = defineProps({
  error: {
    type: Object,
  },
});

const blok = computed(() => {
  return {
    title: {
      type: "doc",
      content: [
        {
          type: "paragraph",
          content: [{ text: props.error.statusMessage || props.error.message || props.error.statusCode, type: "text" }],
        },
      ],
    },
    background: {
      filename: "https://a-us.storyblok.com/f/1001817/2880x738/0b9c12f985/hero-bg.png",
    },
    buttons: [{ url: { id: "", url: "/", linktype: "url", fieldtype: "multilink", cached_url: "/" }, icon: "false", label: "Go back home", theme: "primary", component: "button" }],
  };
});

const title = computed(() => renderRichText(blok.value.title));
const description = computed(() => renderRichText(blok.value.description));

onMounted(() => {
  console.log("error.vue: ", JSON.stringify(props.error, null, 2));
});
</script>
